<template>
  <!-- 绑定手机号 -->
  <div class="bindPhone">
    <div class="main-box">
      <div class="box_main">
        <bindPhone :state=1></bindPhone>
      </div>
    </div>
    
    <bindPhone 
      v-if="showBindPop" 
      :state=2 
      :unionid='unionid'
      @closeBindPop="closeBindPop"></bindPhone>
    <!-- 背景 -->
    <div class="main_bg"></div>
  </div>
</template>

<script>
import bindPhone from "@/components/login/bindPhone.vue";
export default {
  // 可用组件的哈希表
  components: {
    bindPhone
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      showBindPop: false, //打开弹框
      unionid: '', //微信标识
    }
  },
  // 事件处理器
  methods: {
    // 关闭弹框
    closeBindPop() {
      this.showBindPop = false
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.unionid = this.$route.query.unionid || this.$store.state.userInfo.unionid
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  .bindPhone {
    position: relative;
    padding-top: 230px;
    padding-bottom: 250px;
    background-color: #fff;
  }
  .main-box {
    position: relative;
    width: 1100px;
    background-color: #fff;
    box-shadow: 0px 10px 10px 1px rgba(6, 97, 118, 0.15);
    z-index: 2;
  }
  .main_bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 300px;
    background-color: @border-color;
  }
  // 小屏分辨率内容区呈现1200px布局
  @media screen and (max-width: 16000px) {
    .main-box {
      width: 1000px;
    }
    .bindPhone {
      height: 100vh;
      padding-top: 120px;
      padding-bottom: 60px;
    }
    .main_bg {
      height: 220px;
    }
  }
</style>
